<template>
    <div class="amher">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="first" label="家长列表">
                <!-- 表格内容 -->
                <div class="avatar_table">
                    <el-card>
                        <!-- 添加 删除 下拉框 按钮部分 -->
                        <div class="out_sel_btn">
                            <div class="sel_inp">
                                <el-select v-model="p_campus_id" placeholder="校区" clearable @change="initData"
                                    size="mini">
                                    <el-option v-for="item in p_campus_arr" :key="item.uid" :label="item.name"
                                        :value="item.uid">
                                    </el-option>
                                </el-select>
                                <el-input placeholder="请输入家长名称" v-model="p_search_inp" clearable @change="initData"
                                    size="mini">
                                </el-input>
                            </div>
                            <div class="add_del_btn">
                                <el-button type="primary" class="addBtn" @click="parent_add">添加</el-button>
                                <el-button class="delBtn" @click="parent_del">删除</el-button>
                            </div>
                        </div>
                        <!-- 内容表格部分 -->
                        <div class="table_init">
                            <el-table ref="parent_multipleTable" :data="parent_tableData" tooltip-effect="dark"
                                style="width: 100%" @selection-change="parent_SelectionChange"
                                :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column prop="uid" label="ID">
                                </el-table-column>
                                <el-table-column prop="name" label="家长名称">
                                </el-table-column>
                                <el-table-column prop="sex" label="性别">
                                </el-table-column>
                                <el-table-column prop="class_uid" label="所属班级">
                                </el-table-column>
                                <el-table-column prop="campus_uid" label="所属校区">
                                </el-table-column>
                                <el-table-column prop="class_type_uid" label="班级类型">
                                </el-table-column>
                                <el-table-column prop="phone" label="电话">
                                </el-table-column>
                                <el-table-column prop="status" label="状态">
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <span style="margin-right:16px" @click="parent_Edit(scope.row.uid)">编辑</span>
                                        <span @click="parent_Delet(scope.row.uid)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <!-- 分页部分 -->
                        <Paging :count="p_count" :initData="initData" @changePage="pa_changePage"></Paging>
                    </el-card>
                </div>
            </el-tab-pane>
            <el-tab-pane name="second" label="学生列表">
                <!-- 表格内容 -->
                <div class="avatar_table">
                    <el-card>
                        <!-- 添加 删除 下拉框 按钮部分 -->
                        <div class="out_sel_btn">
                            <div class="sel_inp">
                                <el-select v-model="s_campus_id" placeholder="校区" clearable @change="initData1"
                                    size="mini">
                                    <el-option v-for="item in s_campus_arr" :key="item.uid" :label="item.name"
                                        :value="item.uid">
                                    </el-option>
                                </el-select>
                                <el-input placeholder="请输入学生名称" v-model="s_search_inp" clearable @change="initData1"
                                    size="mini">
                                </el-input>
                            </div>
                            <div class="add_del_btn">
                                <el-button type="primary" class="addBtn" @click="student_add">添加</el-button>
                                <el-button class="delBtn" @click="student_del">删除</el-button>
                            </div>
                        </div>
                        <!-- 内容表格部分 -->
                        <div class="table_init">
                            <el-table ref="student_multipleTable" :data="student_tableData" tooltip-effect="dark"
                                style="width: 100%" @selection-change="student_SelectionChange"
                                :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column prop="uid" label="ID">
                                </el-table-column>
                                <el-table-column prop="name" label="学生名称">
                                </el-table-column>
                                <el-table-column prop="sex" label="性别">
                                </el-table-column>
                                <el-table-column prop="age" label="年龄">
                                </el-table-column>
                                <el-table-column prop="campus_uid" label="所属校区">
                                </el-table-column>
                                <el-table-column prop="class_uid" label="所属班级">
                                </el-table-column>
                                <el-table-column prop="parent_uid" label="关联家长">
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <span style="margin-right:16px" @click="student_Edit(scope.row.uid)">编辑</span>
                                        <span @click="student_Delet(scope.row.uid)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <!-- 分页部分 -->
                        <Paging :count="s_count" :initData="initData1" @changePage="st_changePage"></Paging>
                    </el-card>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- 弹框部分 -->
        <!-- 添加编辑弹框 家长*****************************************************************-->
        <el-dialog :visible.sync="parent_dialog" width="828px" :before-close="handleClose" @close="parent_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{parent_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="80px">
                <el-form-item label="家长名称">
                    <el-input v-model="parent" placeholder="请输入家长名称" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio v-model="sex_radio" label="1">男</el-radio>
                    <el-radio v-model="sex_radio" label="2">女</el-radio>
                </el-form-item>
                <el-form-item label="所属校区">
                    <el-select v-model="p_school_id" placeholder="请选择所属校区" multiple clearable @change="Class_handle"
                        size="mini" ref="sel">
                        <el-option v-for="item in p_school_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="班级类型">
                    <el-select v-model="p_type_id" placeholder="请选择班级类型" clearable @change="personlFn" size="mini">
                        <el-option v-for="item in p_type_arr" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属班级">
                    <el-select v-model="p_class_id" placeholder="请选择所属班级" clearable multiple size="mini" ref="selec"
                        @change="handle_sel">
                        <el-option v-for="item in p_class_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电话">
                    <el-input v-model="phone" placeholder="请输入电话" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio v-model="parent_radio" label="1">正常</el-radio>
                    <el-radio v-model="parent_radio" label="2">禁用</el-radio>
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="add_confirm" @click="parent_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="parent_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 添加编辑弹框  学生*****************************************************************-->
        <el-dialog :visible.sync="student_dialog" width="828px" :before-close="handleClose" @close="student_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{student_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="80px">
                <el-form-item label="学生姓名">
                    <el-input v-model="student" placeholder="请输入学生姓名" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio v-model="gender_radio" label="1">男</el-radio>
                    <el-radio v-model="gender_radio" label="2">女</el-radio>
                </el-form-item>
                <el-form-item label="年龄">
                    <el-input v-model="age_num" placeholder="请输入年龄" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="所属校区">
                    <el-select v-model="s_school_id" placeholder="请选择所属校区" clearable @change="school_change"
                        size="mini">
                        <el-option v-for="item in s_school_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属班级">
                    <el-select v-model="s_class_id" placeholder="请选择所属班级" clearable @change="par_stendent" size="mini">
                        <el-option v-for="item in s_class_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="关联家长">
                    <el-select v-model="s_prant_id" placeholder="请选择关联家长" clearable size="mini">
                        <el-option v-for="item in s_prant_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="add_confirm" @click="student_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="student_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">删除</div>
            </div>
            <div class="del_content">{{del_tip}}</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="del_confirm" @click="handle_del">确定</el-button>
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getParentApi, showParentApi, selParentApi, addParentApi, editParentApi, removeParentApi, getStudetApi, showStudetApi, selStudetApi, addStudetApi, editStudetApi, removeStudetApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 登录用户id
            user_id: '',
            // 选项卡
            activeName: 'first',
            bul_show: true,
            tea_show: false,
            // 弹框显示隐藏
            parent_dialog: false,   //添加编辑弹框  家长
            student_dialog: false,   //添加编辑弹框  学生
            del_dialog: false,    //删除弹框
            btn_name: '',
            // ***************家长*****************
            // 每条数据id  家长
            parent_id: '',
            //  表格数据  家长
            parent_tableData: [],
            parent_multiple: [],
            // 分页  家长
            p_page: 1,
            p_count: 0,
            p_max: 10,
            // 搜索输入框
            p_search_inp: '',
            // 搜索下拉框 校区
            p_campus_arr: [],
            p_campus_id: '',
            // 弹框内容
            parent_name: '',  //弹框名字
            parent: '',   //  家长名称
            sex_radio: '1',   //性别
            p_sex: 1,
            p_school_arr: [],   //所属校区
            p_school_id: [],
            p_class_arr: [],   //所属班级
            p_class_id: [],
            p_type_arr: [],   //班级类型
            p_type_id: '',
            phone: '',  //电话
            parent_radio: '1',   //状态
            p_radio: 1,
            // ***************学生****************
            // 每条数据id  学生
            student_id: '',
            //  表格数据  学生
            student_tableData: [],
            student_multiple: [],
            // 分页  学生
            s_page: 1,
            s_count: 0,
            s_max: 10,
            // 搜索输入框
            s_search_inp: '',
            // 搜索下拉框 校区
            s_campus_arr: [],
            s_campus_id: '',
            // 弹框内容
            student_name: '',  //弹框名字
            student: '',   //学生姓名
            gender_radio: '1',   //性别
            gen_sex: 1,
            age_num: '',   //年龄
            s_school_arr: [],   //所属校区
            s_school_id: [],
            s_class_arr: [],   //所属班级
            s_class_id: '',
            s_prant_arr: [],   //关联家长
            s_prant_id: '',
            // 删除数据的数组
            del_arr: [],
            // 删除提示语
            del_tip: '',
        }
    },
    created () {
        this.initData();
    },
    watch: {
        // 监听分页数量的变化  运营
        p_count () {
            if (this.p_count == (this.p_page - 1) * this.p_max && this.p_count != 0) {
                this.p_page -= 1;
                this.initData()
            }
        },
        // 监听分页数量的变化  运营
        s_count () {
            if (this.s_count == (this.s_page - 1) * this.s_max && this.s_count != 0) {
                this.s_page -= 1;
                this.initData1()
            }
        },
    },
    methods: {
        // 选项卡点击
        handleClick () {
            if (this.activeName == 'first') {
                this.initData();
                this.bul_show = true;
                this.tea_show = false;
            } else {
                this.initData1();
                this.bul_show = false;
                this.tea_show = true;
            }
        },
        // ****************家长***********************************************************************
        // 子组件传过来的当前页数   家长
        pa_changePage (data) {
            this.p_page = data;
        },
        // 初始化数据   家长
        initData () {
            this.user_id = sessionStorage.getItem('id');
            getParentApi({
                page: this.p_page,   //当前页
                name: this.p_search_inp,  //校区名称
                campus_id: this.p_campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.parent_tableData = res.data;
                    this.p_count = res.count;
                    this.p_campus_arr = res.campus;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法   家长
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.parent_multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.parent_multiple.clearSelection();
            }
        },
        parent_SelectionChange (val) {
            this.parent_multiple = val;
        },
        // )点击添加按钮
        parent_add () {
            this.parent_dialog = true;
            this.parent_name = '添加';
            this.btn_name = '确认添加';
            showParentApi({
                type: 1,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.p_school_arr = res.campus;   //校区
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 校区下拉框改变
        Class_handle () {
            if (this.p_school_id.length == 0) {
                this.p_type_arr = [];
                this.p_type_id = '';
                this.p_class_arr = [];
                this.p_class_id = []
            } else {
                this.p_type_arr = [];
                this.p_type_id = '';
                this.p_class_arr = [];
                this.p_class_id = []
                selParentApi({
                    campus_uid: this.p_school_id,  //校区id
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.p_type_arr = res.class_type;   //班级类型
                        setTimeout(() => {
                            this.$refs.sel.blur()
                        }, 4000)
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            };
        },
        // 班级类型下拉
        personlFn () {
            if (this.p_type_id == '') {
                this.p_class_arr = [];
                this.p_class_id = [];
            } else {
                this.p_class_arr = [];
                this.p_class_id = [];
                selParentApi({
                    campus_uid: this.p_school_id,  //校区id
                    auth_userid: this.user_id,  //登录用户id
                    class_type_id: this.p_type_id,   //班级类型id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.p_class_arr = res.class;   //班级
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 自动隐藏下拉框
        handle_sel () {
            setTimeout(() => {
                this.$refs.selec.blur()
            }, 4000)
        },
        // 点击修改按钮
        parent_Edit (id) {
            this.parent_dialog = true;
            this.parent_name = '修改';
            this.btn_name = '确认修改';
            this.parent_id = id;
            showParentApi({
                id: id,
                type: 2,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.p_school_arr = res.campus;   //校区
                    this.p_type_arr = res.class_type; //班级类型
                    this.p_class_arr = res.class;   //班级
                    this.parent = res.data.name;   //名称
                    this.p_sex = res.data.sex;   //性别
                    if (this.p_sex == 1) {
                        this.sex_radio = '1'
                    } else {
                        this.sex_radio = '2'
                    }
                    this.p_school_id = res.data.campus_uid;   //校区id
                    this.p_type_id = parseInt(res.data.class_type_uid);  //班级类型id
                    this.p_class_id = res.data.class_uid;   //班级id
                    this.phone = res.data.phone;   //手机号
                    this.p_radio = res.data.status;   //状态
                    if (this.p_radio == 1) {
                        this.parent_radio = '1'
                    } else {
                        this.parent_radio = '2'
                    }
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加修改点击   提交   按钮
        parent_confirm () {
            if (this.sex_radio == '1') {
                this.p_sex = 1;
            } else if (this.sex_radio == '2') {
                this.p_sex = 2;
            };
            if (this.parent_radio == '1') {
                this.p_radio = 1;
            } else if (this.parent_radio == '2') {
                this.p_radio = 2;
            };
            if (this.parent_name == '添加') {
                addParentApi({
                    name: this.parent,  //名称
                    phone: this.phone, //手机号
                    status: this.p_radio,  // 状态
                    auth_userid: this.user_id, //登录用户id
                    campus_uid: this.p_school_id,    //校区id
                    sex: this.p_sex,   //性别 
                    class_type_uid: this.p_type_id,   //班级类型id
                    class_uid: this.p_class_id,  //班级id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData();
                        this.parent_reset();
                        this.parent_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.parent_name == '修改') {
                editParentApi({
                    id: this.parent_id,
                    name: this.parent,  //名称
                    phone: this.phone, //手机号
                    status: this.p_radio,  // 状态
                    auth_userid: this.user_id, //登录用户id
                    campus_uid: this.p_school_id,    //校区id
                    sex: this.p_sex,   //性别 
                    class_type_uid: this.p_type_id,   //班级类型id
                    class_uid: this.p_class_id,  //班级id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData();
                        this.parent_reset();
                        this.parent_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加修改点击   重置  按钮
        parent_reset () {
            this.parent = '';   //  家长名称
            this.sex_radio = '1';  //性别
            this.p_school_id = '';   // 所属校区
            this.p_class_id = [];   //所属班级
            this.p_type_id = '';   //班级类型
            this.phone = '';     //电话
            this.parent_radio = '1';  //状态
        },
        // 点击单个删除按钮
        parent_Delet (id) {
            this.parent_id = id;
            this.del_dialog = true;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        // 多个删除  家长
        parent_del () {
            this.del_dialog = true;
            this.del_tip = '确定删除所选中的选项？';
            for (let i = 0; i < this.parent_multiple.length; i++) {
                let del_id = this.parent_multiple[i].uid;
                this.del_arr.push(del_id);
            };
        },
        // **********************学生*******************************************************************
        // 子组件传过来的当前页数   学生
        st_changePage (data) {
            this.s_page = data;
        },
        // 初始化数据   学生
        initData1 () {
            this.user_id = sessionStorage.getItem('id');
            getStudetApi({
                page: this.s_page,   //当前页
                name: this.s_search_inp,  //校区名称
                campus_id: this.s_campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.student_tableData = res.data;
                    this.s_count = res.count;
                    this.s_campus_arr = res.campus;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法   学生
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.student_multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.student_multipleTable.clearSelection();
            }
        },
        student_SelectionChange (val) {
            this.student_multiple = val;
        },
        // )点击添加按钮
        student_add () {
            this.student_dialog = true;
            this.student_name = '添加';
            this.btn_name = '确认添加';
            showStudetApi({
                type: 1,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.s_school_arr = res.campus;   //校区
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 校区下拉框改变
        school_change () {
            if (this.s_school_id == '') {
                this.s_class_arr = [];
                this.s_class_id = '';
                this.s_prant_arr = [];
                this.s_prant_id = '';
            } else {
                this.s_class_arr = [];
                this.s_class_id = '';
                this.s_prant_arr = [];
                this.s_prant_id = '';
                selStudetApi({
                    campus_uid: this.s_school_id,  //校区id
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.s_class_arr = res.class;   //班级
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 班级下拉
        par_stendent () {
            if (this.s_class_id == '') {
                this.s_prant_arr = [];
                this.s_prant_id = '';
            } else {
                this.s_prant_arr = [];
                this.s_prant_id = '';
                selStudetApi({
                    campus_uid: this.s_school_id,  //校区id
                    auth_userid: this.user_id,  //登录用户id
                    class_uid: this.s_class_id,   //班级id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.s_prant_arr = res.parent;   //家长
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 点击修改按钮
        student_Edit (id) {
            this.student_dialog = true;
            this.student_name = '修改';
            this.btn_name = '确认修改';
            this.student_id = id;
            showStudetApi({
                id: id,
                type: 2,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.s_school_arr = res.campus;   //校区
                    this.s_class_arr = res.class;    //班级
                    this.s_prant_arr = res.parent;   //家长
                    this.student = res.data.name;   //名称
                    this.gen_sex = res.data.sex;   //性别
                    if (this.gen_sex == 1) {
                        this.gender_radio = '1'
                    } else {
                        this.gender_radio = '2'
                    }
                    this.age_num = res.data.age;    //年龄
                    this.s_school_id = res.data.campus_uid;   //校区id
                    this.s_class_id = res.data.class_uid;    //班级id
                    this.s_prant_id = res.data.parent_uid;    //家长id
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加修改点击   提交   按钮
        student_confirm () {
            this.student_dialog = false;
            if (this.gender_radio == '1') {
                this.gen_sex = 1;
            } else if (this.gender_radio == '2') {
                this.gen_sex = 2;
            };
            if (this.student_name == '添加') {
                addStudetApi({
                    name: this.student,  //名称
                    age: this.age_num, //年龄
                    auth_userid: this.user_id, //登录用户id
                    campus_uid: this.s_school_id,    //校区id
                    sex: this.gen_sex,   //性别 
                    parent_uid: this.s_prant_id,   //家长id
                    class_uid: this.s_class_id,  //班级id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData1();
                        this.student_reset();
                        this.student_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.student_name == '修改') {
                editStudetApi({
                    id: this.student_id,
                    name: this.student,  //名称
                    age: this.age_num, //年龄
                    auth_userid: this.user_id, //登录用户id
                    campus_uid: this.s_school_id,    //校区id
                    sex: this.gen_sex,   //性别 
                    parent_uid: this.s_prant_id,   //家长id
                    class_uid: this.s_class_id,  //班级id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData1();
                        this.student_reset();
                        this.student_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加修改点击   重置  按钮
        student_reset () {
            this.student = '';   //学生姓名
            this.gender_radio = '1';   //性别
            this.age_num = '';   //年龄
            this.s_school_id = '';   //所属校区
            this.s_class_id = '';    //所属班级
            this.s_prant_id = '';    //关联家长
            this.student_radio = '1';  //状态
        },
        // 点击单个删除按钮
        student_Delet (id) {
            this.student_id = id;
            this.del_dialog = true;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        // 多个删除  学生
        student_del () {
            this.del_dialog = true;
            this.del_tip = '确定删除所选中的选项？';
            for (let i = 0; i < this.student_multiple.length; i++) {
                let del_id = this.student_multiple[i].uid;
                this.del_arr.push(del_id);
            };
        },
        remove_Fn () {
            this.del_dialog = false;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除弹框点击  提交 按钮
        handle_del () {
            if (this.activeName == 'first') {
                removeParentApi({
                    id: this.del_arr,
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('删除成功');
                        this.del_dialog = false;
                        this.initData();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else {
                removeStudetApi({
                    id: this.del_arr,
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('删除成功');
                        this.del_dialog = false;
                        this.initData1();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 弹窗关闭
        handleClose () {
            this.parent_dialog = false;
            this.student_dialog = false;
            this.del_dialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.amher {
    width: 100%;
    height: 100%;
    // 选项卡
    .el-tabs {
        ::v-deep.el-tabs__active-bar {
            display: none;
        }
        ::v-deep.el-tabs__item {
            width: 112px;
            height: 40px;
            text-align: center;
            color: #525252;
            font-size: 18px;
            font-weight: 900;
        }
        // ::v-deep.el-tabs__item:hover {
        //     color: #333333;
        //     font-size: 18px;
        //     font-weight: 900;
        // }
        ::v-deep.el-tabs__item.is-active {
            width: 112px;
            height: 40px;
            // text-align: center;
            border-radius: 24px;
            background: #2479dd;
            color: #ffffff;
            font-weight: 500;
        }
        ::v-deep#tab-first {
            text-align: right;
        }
        ::v-deep#tab-second {
            text-align: left;
        }
        ::v-deep.el-tabs__nav-wrap::after {
            position: static !important;
        }
        // 表格显示内容
        .avatar_table {
            margin-bottom: 20px;
            // 下拉框  添加  删除按钮部分
            .out_sel_btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // 下拉框部分
                // 下拉框部分
                .sel_inp {
                    margin-top: 15px;
                    display: flex;
                    .el-select {
                        width: 140px;
                        height: 30px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        ::v-deep.el-input__inner {
                            width: 140px;
                            height: 30px;
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                            ::v-deep.el-select__caret {
                                line-height: 30px;
                            }
                            ::v-deep.el-input__icon {
                                line-height: 30px;
                            }
                        }
                    }
                    .el-input {
                        width: 155px;
                        height: 30px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-left: 0px;
                        ::v-deep.el-input__inner {
                            width: 155px;
                            height: 30px;
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            border-left: 0px;
                        }
                    }
                }
                // 添加  删除按钮部分
                .add_del_btn {
                    text-align: right;
                    .el-button {
                        width: 80px;
                        height: 30px;
                        line-height: 4px;
                    }
                    .addBtn {
                        margin-right: 8px;
                    }
                }
            }
            // 表格内容
            .table_init {
                margin-top: 20px;
                text-align: center;
                // 操作按钮
                span {
                    border: none;
                    width: 24px;
                    height: 18px;
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                }
            }
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            display: flex;
            align-items: center;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            .sd {
                width: 18px;
                height: 4px;
                margin-right: 8px;
                background: #2479dd;
            }
            .ax {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 100px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-right: 16px;
            }
        }
    }
}
</style>